/*
 Import all product specific js
 */
import PageManager from './page-manager';
import Review from './product/reviews';
import collapsibleFactory from './common/collapsible';
import ProductDetails from './common/product-details';
import videoGallery from './product/video-gallery';
import { classifyForm } from './common/form-utils';

export default class Product extends PageManager {
    constructor(context) {
        super(context);
        this.url = window.location.href;
        this.$reviewLink = $('[data-reveal-id="modal-review-form"]');
        this.$bulkPricingLink = $('[data-reveal-id="modal-bulk-pricing"]');
    }

    onReady() {
        // Listen for foundation modal close events to sanitize URL after review.
        $(document).on('close.fndtn.reveal', () => {
            if (this.url.indexOf('#write_review') !== -1 && typeof window.history.replaceState === 'function') {
                window.history.replaceState(null, document.title, window.location.pathname);
            }
        });

        let validator;

        // Init collapsible
        collapsibleFactory();

        this.productDetails = new ProductDetails($('.productView'), this.context, window.BCData.product_attributes);
        this.productDetails.setProductVariant();

        videoGallery();

        const $reviewForm = classifyForm('.writeReview-form');
        const review = new Review($reviewForm);

        $('body').on('click', '[data-reveal-id="modal-review-form"]', () => {
            validator = review.registerValidation(this.context);
        });

        $reviewForm.on('submit', () => {
            if (validator) {
                validator.performCheck();
                return validator.areAll('valid');
            }

            return false;
        });

        this.productReviewHandler();
        this.bulkPricingHandler();
    }

    static calculateInferredPrices() {
        console.log('calculate inferred prices')
        Product.calculateWattagePrice()
        Product.calculateKWHPrice()
    }

    static calculateKWHPrice() {
        var target = $('[data-kwh][data-price]')
        if (!(target[0] instanceof Element))
            return;
        
            for (var i = 0; i < target.length; i++) {
                var element = target[i];

                var price = element.getAttribute('data-price')
                var kwh = element.getAttribute('data-kwh')

                if (!(price && kwh))
                    continue;
                
                if (!(typeof price === 'string' && price.match(/\d+/) || typeof price === 'number')) {
                    continue;
                }

                kwh = kwh.match(/([\d\.]+)\w+/)

                if (!kwh) {
                    continue
                }
                
                kwh = Number.parseFloat(kwh[0])
                price = Number.parseInt(price)

                element.innerHTML = '$' + (price / kwh).toFixed(2) + ' / Kilowatt-hour'


            }
    }

    static calculateWattagePrice() {
        var target = $('[data-wattage][data-price]')

        // target element is not found, stop processing
        if (!(target[0] instanceof Element)) {
            return;
        }

        for (var i = 0; i < target.length; i++) {
            var element = target[i];

            var price = element.getAttribute('data-price')
            var wattage = element.getAttribute('data-wattage')
            var qty = element.getAttribute('data-qty')

            // wattage and price attributes are not set, stop procesing
            if (!(price && wattage)) {
                continue;
            }

            // verify price is numeric
            if (!(typeof price === 'string' && price.match(/\d+/) || typeof price === 'number')) {
                continue;
            }

            // verify wattage is to numeric + W (123W) for conversion
            if (!wattage.toUpperCase().match(/\d+[W]/)) {
                continue;
            }

            wattage = Number.parseInt(wattage.substring(0, wattage.length - 1))


            // qty exists, multiply by wattage
            if (qty && qty.split(' ').length > 0 && qty.split(' ')[0].match(/\d+/)) {
                qty = Number.parseInt(qty.split(' ')[0])
                wattage = wattage * qty
            }
            price = Number.parseInt(price)

            element.innerHTML = '$' + (price / wattage).toFixed(3) + ' / watt'
        }
    }

    productReviewHandler() {
        if (this.url.indexOf('#write_review') !== -1) {
            this.$reviewLink.trigger('click');
        }
    }
    bulkPricingHandler() {
        if (this.url.indexOf('#bulk_pricing') !== -1) {
            this.$bulkPricingLink.trigger('click');
        }
    }
}
