/*eslint-disable*/
import utils from '@bigcommerce/stencil-utils';
import modalFactory, { showAlertModal } from '../global/modal';
import _ from 'lodash';


class customPopUpBox {
    constructor() {
        $(document).on('click', '.addtocart-btn', (event) => {
            // const productid = $(event.currentTarget).attr('href').split('product_id=')[1];
            // this.customAddToCartProduct(event, productid);
        });

        this.previewModal = modalFactory('#previewCustomModal')[0];
    }

    filterEmptyFilesFromForm(formData) {
        try {
            for (const [key, val] of formData) {
                if (val instanceof File && !val.name && !val.size) {
                    formData.delete(key);
                }
            }
        } catch (e) {
            console.error(e); // eslint-disable-line no-console
        }
        return formData;
    }

    updateCartContent(modal, cartItemId, onComplete) {
        this.getCartContent(cartItemId, (err, response) => {
            if (err) {
                return;
            }

            modal.updateContent(response);
            // Update cart counter
            const $body = $('body');
            const $cartQuantity = $('[data-cart-quantity]', modal.$content);
            const $cartCounter = $('.navUser-action .cart-count');
            const quantity = $cartQuantity.data('cartQuantity') || 0;
            const $cartPrice = $(response).find('.previewCartCheckout-price');

            $cartCounter.addClass('cart-count--positive');
            $body.trigger('cart-quantity-update', quantity);
            if(quantity > 0) {
                $('.cart-item-counts em').text('('+quantity+')');
            }
            $('.cart-item-counts span').text($cartPrice.text().trim());

            if (onComplete) {
                onComplete(response);
            }
        });
    }

    getCartContent(cartItemId, onComplete) {
        const options = {
            template: 'cart/preview',
            params: {
                suggest: cartItemId,
            },
            config: {
                cart: {
                    suggestions: {
                        limit: 4,
                    },
                },
            },
        };

        utils.api.cart.getContent(options, onComplete);
    }

    customAddToCartProduct(event, proId) {

        const $addToCartBtn = event.currentTarget;

        // Do not do AJAX if browser doesn't support FormData
        if (window.FormData === undefined) {
            return;
        }

        // Prevent default
        event.preventDefault();

        $($addToCartBtn).text('Adding to cart...').css('pointer-events', 'none');

        let formData = new FormData();
        formData.append('action', 'add');
        formData.append('product_id', proId);
        formData.append('qty[]', 1);

        utils.api.cart.itemAdd(this.filterEmptyFilesFromForm(formData), (err, response) => {
            const errorMessage = err || response.data.error;
            console.log($addToCartBtn);

            $($addToCartBtn).text('Add To Cart').css('pointer-events', 'auto');

            // Guard statement
            if (errorMessage) {
                // Strip the HTML from the error message
                const tmp = document.createElement('DIV');
                tmp.innerHTML = errorMessage;

                return showAlertModal(tmp.textContent || tmp.innerText);
            }

            if (this.previewModal) {
                this.previewModal.open();
                this.updateCartContent(this.previewModal, response.data.cart_item.id);
            }

        });

    }
}


export default function customAddToCartBox() {
    const modal = new customPopUpBox();
    return modal;
}

export function customCartFunc() {
    const PopUpBox = customAddToCartBox();
    return PopUpBox;
}

/*eslint-enable*/
